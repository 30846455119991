<template>
  <v-container>
    <v-row
      class="d-flex flex-row align-center justify-space-between px-2"
    >
      <v-col cols="2" class="py-0">
        <v-tooltip v-if="item.isDefault" top content-class="tooltip" color="white">
          <template #activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on">
              star_border
            </v-icon>
          </template>
          <div class="tooltip-text pa-2" v-text="$t('organization.settings.tooltip')" />
        </v-tooltip>
      </v-col>
      <v-col cols="8">
        {{ getLanguageName(item.id) }}
      </v-col>
      <v-col cols="2" style="padding: 0 !important;">
        <v-menu>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" icon v-bind="attrs" v-on="on" @click.prevent @mousedown.stop>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$emit('onDelete')">
              <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('onSelectDefault')">
              <v-list-item-title>{{ $t('common.default') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('onUpdate')">
              <v-list-item-title>{{ $t('common.modify') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import localeUtils from '@/utils/mixins/localeUtils'
import i18nLanguages from '@/utils/mixins/i18nLanguages'

export default {
  name: 'LanguageListItem',
  mixins: [i18nLanguages, localeUtils],
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      addLanguageDialog: false,
      modifyLanguageDialog: false,
    }
  },
}
</script>
