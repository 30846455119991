export default [
  {
    country: 'AD',
    countryName: 'Andorra',
    code: 376,
    countryFlag: '🇦🇩',
  },
  {
    country: 'AE',
    countryName: 'دولة الإمارات العربية المتحدة',
    code: 971,
    countryFlag: '🇦🇪',
  },
  {
    country: 'AF',
    countryName: 'افغانستان',
    code: 93,
    countryFlag: '🇦🇫',
  },
  {
    country: 'AG',
    countryName: 'Antigua and Barbuda',
    code: 1,
    countryFlag: '🇦🇬',
  },
  {
    country: 'AI',
    countryName: 'Anguilla',
    code: 1,
    countryFlag: '🇦🇮',
  },
  {
    country: 'AL',
    countryName: 'Shqipëria',
    code: 355,
    countryFlag: '🇦🇱',
  },
  {
    country: 'AM',
    countryName: 'Հայաստան',
    code: 374,
    countryFlag: '🇦🇲',
  },
  {
    country: 'AO',
    countryName: 'Angola',
    code: 244,
    countryFlag: '🇦🇴',
  },
  {
    country: 'AQ',
    countryName: 'Antarctica',
    code: 672,
    countryFlag: '🇦🇶',
  },
  {
    country: 'AR',
    countryName: 'Argentina',
    code: '54',
    countryFlag: '🇦🇷',
  },
  {
    country: 'AT',
    countryName: 'Österreich',
    code: 43,
    countryFlag: '🇦🇹',
  },
  {
    country: 'AU',
    countryName: 'Australia',
    code: 61,
    countryFlag: '🇦🇺',
  },
  {
    country: 'AW',
    countryName: 'Aruba',
    code: 297,
    countryFlag: '🇦🇼',
  },
  {
    country: 'AX',
    countryName: 'Åland',
    code: 358,
    countryFlag: '🇦🇽',
  },
  {
    country: 'AZ',
    countryName: 'Azərbaycan',
    code: 994,
    countryFlag: '🇦🇿',
  },
  {
    country: 'BA',
    countryName: 'Bosna i Hercegovina',
    code: 387,
    countryFlag: '🇧🇦',
  },
  {
    country: 'BB',
    countryName: 'Barbados',
    code: 1,
    countryFlag: '🇧🇧',
  },
  {
    country: 'BD',
    countryName: 'Bangladesh',
    code: 880,
    countryFlag: '🇧🇩',
  },
  {
    country: 'BE',
    countryName: 'België',
    code: 32,
    countryFlag: '🇧🇪',
  },
  {
    country: 'BF',
    countryName: 'Burkina Faso',
    code: 226,
    countryFlag: '🇧🇫',
  },
  {
    country: 'BG',
    countryName: 'България',
    code: 359,
    countryFlag: '🇧🇬',
  },
  {
    country: 'BH',
    countryName: '‏البحرين',
    code: 973,
    countryFlag: '🇧🇭',
  },
  {
    country: 'BI',
    countryName: 'Burundi',
    code: 257,
    countryFlag: '🇧🇮',
  },
  {
    country: 'BJ',
    countryName: 'Bénin',
    code: 229,
    countryFlag: '🇧🇯',
  },
  {
    country: 'BL',
    countryName: 'Saint-Barthélemy',
    code: 590,
    countryFlag: '🇧🇱',
  },
  {
    country: 'BM',
    countryName: 'Bermuda',
    code: 1,
    countryFlag: '🇧🇲',
  },
  {
    country: 'BN',
    countryName: 'Negara Brunei Darussalam',
    code: 673,
    countryFlag: '🇧🇳',
  },
  {
    country: 'BO',
    countryName: 'Bolivia',
    code: 591,
    countryFlag: '🇧🇴',
  },
  {
    country: 'BQ',
    countryName: 'Bonaire',
    code: 5997,
    countryFlag: '🇧🇶',
  },
  {
    country: 'BR',
    countryName: 'Brasil',
    code: 55,
    countryFlag: '🇧🇷',
  },
  {
    country: 'BS',
    countryName: 'Bahamas',
    code: 1,
    countryFlag: '🇧🇸',
  },
  {
    country: 'BT',
    countryName: 'ʼbrug-yul',
    code: 975,
    countryFlag: '🇧🇹',
  },
  {
    country: 'BV',
    countryName: 'Bouvetøya',
    code: 47,
    countryFlag: '🇧🇻',
  },
  {
    country: 'BW',
    countryName: 'Botswana',
    code: 267,
    countryFlag: '🇧🇼',
  },
  {
    country: 'BY',
    countryName: 'Белару́сь',
    code: 375,
    countryFlag: '🇧🇾',
  },
  {
    country: 'BZ',
    countryName: 'Belize',
    code: 501,
    countryFlag: '🇧🇿',
  },
  {
    country: 'CA',
    countryName: 'Canada',
    code: 1,
    countryFlag: '🇨🇦',
  },
  {
    country: 'CC',
    countryName: 'Cocos (Keeling) Islands',
    code: 61,
    countryFlag: '🇨🇨',
  },
  {
    country: 'CD',
    countryName: 'République démocratique du Congo',
    code: 243,
    countryFlag: '🇨🇩',
  },
  {
    country: 'CF',
    countryName: 'Ködörösêse tî Bêafrîka',
    code: 236,
    countryFlag: '🇨🇫',
  },
  {
    country: 'CG',
    countryName: 'République du Congo',
    code: 242,
    countryFlag: '🇨🇬',
  },
  {
    country: 'CH',
    countryName: 'Schweiz',
    code: 41,
    countryFlag: '🇨🇭',
  },
  {
    country: 'CI',
    countryName: 'Côte d\'Ivoire',
    code: 225,
    countryFlag: '🇨🇮',
  },
  {
    country: 'CK',
    countryName: 'Cook Islands',
    code: 682,
    countryFlag: '🇨🇰',
  },
  {
    country: 'CL',
    countryName: 'Chile',
    code: 56,
    countryFlag: '🇨🇱',
  },
  {
    country: 'CM',
    countryName: 'Cameroon',
    code: 237,
    countryFlag: '🇨🇲',
  },
  {
    country: 'CN',
    countryName: '中国',
    code: 86,
    countryFlag: '🇨🇳',
  },
  {
    country: 'CO',
    countryName: 'Colombia',
    code: 57,
    countryFlag: '🇨🇴',
  },
  {
    country: 'CR',
    countryName: 'Costa Rica',
    code: 506,
    countryFlag: '🇨🇷',
  },
  {
    country: 'CU',
    countryName: 'Cuba',
    code: 53,
    countryFlag: '🇨🇺',
  },
  {
    country: 'CV',
    countryName: 'Cabo Verde',
    code: 238,
    countryFlag: '🇨🇻',
  },
  {
    country: 'CW',
    countryName: 'Curaçao',
    code: 5999,
    countryFlag: '🇨🇼',
  },
  {
    country: 'CX',
    countryName: 'Christmas Island',
    code: 61,
    countryFlag: '🇨🇽',
  },
  {
    country: 'CY',
    countryName: 'Κύπρος',
    code: 357,
    countryFlag: '🇨🇾',
  },
  {
    country: 'CZ',
    countryName: 'Česká republika',
    code: 420,
    countryFlag: '🇨🇿',
  },
  {
    country: 'DE',
    countryName: 'Deutschland',
    code: 49,
    countryFlag: '🇩🇪',
  },
  {
    country: 'DJ',
    countryName: 'Djibouti',
    code: 253,
    countryFlag: '🇩🇯',
  },
  {
    country: 'DK',
    countryName: 'Danmark',
    code: 45,
    countryFlag: '🇩🇰',
  },
  {
    country: 'DM',
    countryName: 'Dominica',
    code: 1,
    countryFlag: '🇩🇲',
  },
  {
    country: 'DO',
    countryName: 'República Dominicana',
    code: 1,
    countryFlag: '🇩🇴',
  },
  {
    country: 'DZ',
    countryName: 'الجزائر',
    code: 213,
    countryFlag: '🇩🇿',
  },
  {
    country: 'EC',
    countryName: 'Ecuador',
    code: 593,
    countryFlag: '🇪🇨',
  },
  {
    country: 'EE',
    countryName: 'Eesti',
    code: 372,
    countryFlag: '🇪🇪',
  },
  {
    country: 'EG',
    countryName: 'مصر‎',
    code: 20,
    countryFlag: '🇪🇬',
  },
  {
    country: 'EH',
    countryName: 'الصحراء الغربية',
    countryFlag: '🇪🇭',
  },
  {
    country: 'ER',
    countryName: 'ኤርትራ',
    code: 291,
    countryFlag: '🇪🇷',
  },
  {
    country: 'ES',
    countryName: 'España',
    code: 34,
    countryFlag: '🇪🇸',
  },
  {
    country: 'ET',
    countryName: 'ኢትዮጵያ',
    code: 251,
    countryFlag: '🇪🇹',
  },
  {
    country: 'FI',
    countryName: 'Suomi',
    code: 358,
    countryFlag: '🇫🇮',
  },
  {
    country: 'FJ',
    countryName: 'Fiji',
    code: 679,
    countryFlag: '🇫🇯',
  },
  {
    country: 'FK',
    countryName: 'Falkland Islands',
    code: 500,
    countryFlag: '🇫🇰',
  },
  {
    country: 'FM',
    countryName: 'Micronesia',
    code: 691,
    countryFlag: '🇫🇲',
  },
  {
    country: 'FO',
    countryName: 'Føroyar',
    code: 298,
    countryFlag: '🇫🇴',
  },
  {
    country: 'FR',
    countryName: 'France',
    code: 33,
    countryFlag: '🇫🇷',
  },
  {
    country: 'GA',
    countryName: 'Gabon',
    code: 241,
    countryFlag: '🇬🇦',
  },
  {
    country: 'GB',
    countryName: 'United Kingdom',
    code: 44,
    countryFlag: '🇬🇧',
  },
  {
    country: 'GD',
    countryName: 'Grenada',
    code: 1,
    countryFlag: '🇬🇩',
  },
  {
    country: 'GE',
    countryName: 'საქართველო',
    code: 995,
    countryFlag: '🇬🇪',
  },
  {
    country: 'GF',
    countryName: 'Guyane française',
    code: 594,
    countryFlag: '🇬🇫',
  },
  {
    country: 'GG',
    countryName: 'Guernsey',
    code: 44,
    countryFlag: '🇬🇬',
  },
  {
    country: 'GH',
    countryName: 'Ghana',
    code: 233,
    countryFlag: '🇬🇭',
  },
  {
    country: 'GI',
    countryName: 'Gibraltar',
    code: 350,
    countryFlag: '🇬🇮',
  },
  {
    country: 'GL',
    countryName: 'Kalaallit Nunaat',
    code: 299,
    countryFlag: '🇬🇱',
  },
  {
    country: 'GM',
    countryName: 'Gambia',
    code: 220,
    countryFlag: '🇬🇲',
  },
  {
    country: 'GN',
    countryName: 'Guinée',
    code: 224,
    countryFlag: '🇬🇳',
  },
  {
    country: 'GP',
    countryName: 'Guadeloupe',
    code: 590,
    countryFlag: '🇬🇵',
  },
  {
    country: 'GQ',
    countryName: 'Guinea Ecuatorial',
    code: 240,
    countryFlag: '🇬🇶',
  },
  {
    country: 'GR',
    countryName: 'Ελλάδα',
    code: 30,
    countryFlag: '🇬🇷',
  },
  {
    country: 'GS',
    countryName: 'South Georgia',
    code: 500,
    countryFlag: '🇬🇸',
  },
  {
    country: 'GT',
    countryName: 'Guatemala',
    code: 502,
    countryFlag: '🇬🇹',
  },
  {
    country: 'GU',
    countryName: 'Guam',
    code: 1,
    countryFlag: '🇬🇺',
  },
  {
    country: 'GW',
    countryName: 'Guiné-Bissau',
    code: 245,
    countryFlag: '🇬🇼',
  },
  {
    country: 'GY',
    countryName: 'Guyana',
    code: 592,
    countryFlag: '🇬🇾',
  },
  {
    country: 'HK',
    countryName: '香港',
    code: 852,
    countryFlag: '🇭🇰',
  },
  {
    country: 'HM',
    countryName: 'Heard Island and McDonald Islands',
    code: 61,
    countryFlag: '🇭🇲',
  },
  {
    country: 'HN',
    countryName: 'Honduras',
    code: 504,
    countryFlag: '🇭🇳',
  },
  {
    country: 'HR',
    countryName: 'Hrvatska',
    code: 385,
    countryFlag: '🇭🇷',
  },
  {
    country: 'HT',
    countryName: 'Haïti',
    code: 509,
    countryFlag: '🇭🇹',
  },
  {
    country: 'HU',
    countryName: 'Magyarország',
    code: 36,
    countryFlag: '🇭🇺',
  },
  {
    country: 'ID',
    countryName: 'Indonesia',
    code: 62,
    countryFlag: '🇮🇩',
  },
  {
    country: 'IE',
    countryName: 'Éire',
    code: 353,
    countryFlag: '🇮🇪',
  },
  {
    country: 'IL',
    countryName: 'יִשְׂרָאֵל',
    code: 972,
    countryFlag: '🇮🇱',
  },
  {
    country: 'IM',
    countryName: 'Isle of Man',
    code: 44,
    countryFlag: '🇮🇲',
  },
  {
    country: 'IN',
    countryName: 'भारत',
    code: 91,
    countryFlag: '🇮🇳',
  },
  {
    country: 'IO',
    countryName: 'British Indian Ocean Territory',
    code: 246,
    countryFlag: '🇮🇴',
  },
  {
    country: 'IQ',
    countryName: 'العراق',
    code: 964,
    countryFlag: '🇮🇶',
  },
  {
    country: 'IR',
    countryName: 'ایران',
    code: 98,
    countryFlag: '🇮🇷',
  },
  {
    country: 'IS',
    countryName: 'Ísland',
    code: 354,
    countryFlag: '🇮🇸',
  },
  {
    country: 'IT',
    countryName: 'Italia',
    code: 39,
    countryFlag: '🇮🇹',
  },
  {
    country: 'JE',
    countryName: 'Jersey',
    code: 44,
    countryFlag: '🇯🇪',
  },
  {
    country: 'JM',
    countryName: 'Jamaica',
    code: 1,
    countryFlag: '🇯🇲',
  },
  {
    country: 'JO',
    countryName: 'الأردن',
    code: 962,
    countryFlag: '🇯🇴',
  },
  {
    country: 'JP',
    countryName: '日本',
    code: 81,
    countryFlag: '🇯🇵',
  },
  {
    country: 'KE',
    countryName: 'Kenya',
    code: 254,
    countryFlag: '🇰🇪',
  },
  {
    country: 'KG',
    countryName: 'Кыргызстан',
    code: 996,
    countryFlag: '🇰🇬',
  },
  {
    country: 'KH',
    countryName: 'Kâmpŭchéa',
    code: 855,
    countryFlag: '🇰🇭',
  },
  {
    country: 'KI',
    countryName: 'Kiribati',
    code: 686,
    countryFlag: '🇰🇮',
  },
  {
    country: 'KM',
    countryName: 'Komori',
    code: 269,
    countryFlag: '🇰🇲',
  },
  {
    country: 'KN',
    countryName: 'Saint Kitts and Nevis',
    code: 1,
    countryFlag: '🇰🇳',
  },
  {
    country: 'KP',
    countryName: '북한',
    code: 850,
  },
  {
    country: 'KR',
    countryName: '대한민국',
    code: 82,
    countryFlag: '🇰🇷',
  },
  {
    country: 'KW',
    countryName: 'الكويت',
    code: 965,
    countryFlag: '🇰🇼',
  },
  {
    country: 'KY',
    countryName: 'Cayman Islands',
    code: 1,
    countryFlag: '🇰🇾',
  },
  {
    country: 'KZ',
    countryName: 'Қазақстан',
    // have multiple code
    // code:76,77,
    code: 76,
    countryFlag: '🇰🇿',
  },
  {
    country: 'LA',
    countryName: 'ສປປລາວ',
    code: 856,
    countryFlag: '🇱🇦',
  },
  {
    country: 'LB',
    countryName: 'لبنان',
    code: 961,
    countryFlag: '🇱🇧',
  },
  {
    country: 'LC',
    countryName: 'Saint Lucia',
    code: 1,
    countryFlag: '🇱🇨',
  },
  {
    country: 'LI',
    countryName: 'Liechtenstein',
    code: 423,
    countryFlag: '🇱🇮',
  },
  {
    country: 'LK',
    countryName: 'śrī laṃkāva',
    code: 94,
    countryFlag: '🇱🇰',
  },
  {
    country: 'LR',
    countryName: 'Liberia',
    code: 231,
    countryFlag: '🇱🇷',
  },
  {
    country: 'LS',
    countryName: 'Lesotho',
    code: 266,
    countryFlag: '🇱🇸',
  },
  {
    country: 'LT',
    countryName: 'Lietuva',
    code: 370,
    countryFlag: '🇱🇹',
  },
  {
    country: 'LU',
    countryName: 'Luxembourg',
    code: 352,
    countryFlag: '🇱🇺',
  },
  {
    country: 'LV',
    countryName: 'Latvija',
    code: 371,
    countryFlag: '🇱🇻',
  },
  {
    country: 'LY',
    countryName: '‏ليبيا',
    code: 218,
    countryFlag: '🇱🇾',
  },
  {
    country: 'MA',
    countryName: 'المغرب',
    code: 212,
    countryFlag: '🇲🇦',
  },
  {
    country: 'MC',
    countryName: 'Monaco',
    code: 377,
    countryFlag: '🇲🇨',
  },
  {
    country: 'MD',
    countryName: 'Moldova',
    code: 373,
    countryFlag: '🇲🇩',
  },
  {
    country: 'ME',
    countryName: 'Црна Гора',
    code: 382,
    countryFlag: '🇲🇪',
  },
  {
    country: 'MF',
    countryName: 'Saint-Martin',
    code: 590,
    countryFlag: '🇲🇫',
  },
  {
    country: 'MG',
    countryName: 'Madagasikara',
    code: 261,
    countryFlag: '🇲🇬',
  },
  {
    country: 'MH',
    countryName: 'M̧ajeļ',
    code: 692,
    countryFlag: '🇲🇭',
  },
  {
    country: 'MK',
    countryName: 'Северна Македонија',
    code: 389,
    countryFlag: '🇲🇰',
  },
  {
    country: 'ML',
    countryName: 'Mali',
    code: 223,
    countryFlag: '🇲🇱',
  },
  {
    country: 'MM',
    countryName: 'မြန်မာ',
    code: 95,
    countryFlag: '🇲🇲',
  },
  {
    country: 'MN',
    countryName: 'Монгол улс',
    code: 976,
    countryFlag: '🇲🇳',
  },
  {
    country: 'MO',
    countryName: '澳門',
    code: 853,
    countryFlag: '🇲🇴',
  },
  {
    country: 'MP',
    countryName: 'Northern Mariana Islands',
    code: 1,
    countryFlag: '🇲🇵',
  },
  {
    country: 'MQ',
    countryName: 'Martinique',
    code: 596,
    countryFlag: '🇲🇶',
  },
  {
    country: 'MR',
    countryName: 'موريتانيا',
    code: 222,
    countryFlag: '🇲🇷',
  },
  {
    country: 'MS',
    countryName: 'Montserrat',
    code: 1,
    countryFlag: '🇲🇸',
  },
  {
    country: 'MT',
    countryName: 'Malta',
    code: 356,
    countryFlag: '🇲🇹',
  },
  {
    country: 'MU',
    countryName: 'Maurice',
    code: 230,
    countryFlag: '🇲🇺',
  },
  {
    country: 'MV',
    countryName: 'Maldives',
    code: 960,
    countryFlag: '🇲🇻',
  },
  {
    country: 'MW',
    countryName: 'Malawi',
    code: 265,
    countryFlag: '🇲🇼',
  },
  {
    country: 'MX',
    countryName: 'México',
    code: 52,
    countryFlag: '🇲🇽',
  },
  {
    country: 'MY',
    countryName: 'Malaysia',
    code: 60,
    countryFlag: '🇲🇾',
  },
  {
    country: 'MZ',
    countryName: 'Moçambique',
    code: 258,
    countryFlag: '🇲🇿',
  },
  {
    country: 'NA',
    countryName: 'Namibia',
    code: 264,
    countryFlag: '🇳🇦',
  },
  {
    country: 'NC',
    countryName: 'Nouvelle-Calédonie',
    code: 687,
    countryFlag: '🇳🇨',
  },
  {
    country: 'NE',
    countryName: 'Niger',
    code: 227,
    countryFlag: '🇳🇪',
  },
  {
    country: 'NF',
    countryName: 'Norfolk Island',
    code: 672,
    countryFlag: '🇳🇫',
  },
  {
    country: 'NG',
    countryName: 'Nigeria',
    code: 234,
    countryFlag: '🇳🇬',
  },
  {
    country: 'NI',
    countryName: 'Nicaragua',
    code: 505,
    countryFlag: '🇳🇮',
  },
  {
    country: 'NL',
    countryName: 'Nederland',
    code: 31,
    countryFlag: '🇳🇱',
  },
  {
    country: 'NO',
    countryName: 'Norge',
    code: 47,
    countryFlag: '🇳🇴',
  },
  {
    country: 'NP',
    countryName: 'नपल',
    code: 977,
    countryFlag: '🇳🇵',
  },
  {
    country: 'NR',
    countryName: 'Nauru',
    code: 674,
    countryFlag: '🇳🇷',
  },
  {
    country: 'NU',
    countryName: 'Niuē',
    code: 683,
    countryFlag: '🇳🇺',
  },
  {
    country: 'NZ',
    countryName: 'New Zealand',
    code: 64,
    countryFlag: '🇳🇿',
  },
  {
    country: 'OM',
    countryName: 'عمان',
    code: 968,
    countryFlag: '🇴🇲',
  },
  {
    country: 'PA',
    countryName: 'Panamá',
    code: 507,
    countryFlag: '🇵🇦',
  },
  {
    country: 'PE',
    countryName: 'Perú',
    code: 51,
    countryFlag: '🇵🇪',
  },
  {
    country: 'PF',
    countryName: 'Polynésie française',
    code: 689,
    countryFlag: '🇵🇫',
  },
  {
    country: 'PG',
    countryName: 'Papua Niugini',
    code: 675,
    countryFlag: '🇵🇬',
  },
  {
    country: 'PH',
    countryName: 'Pilipinas',
    code: 63,
    countryFlag: '🇵🇭',
  },
  {
    country: 'PK',
    countryName: 'Pakistan',
    code: 92,
    countryFlag: '🇵🇰',
  },
  {
    country: 'PL',
    countryName: 'Polska',
    code: 48,
    countryFlag: '🇵🇱',
  },
  {
    country: 'PM',
    countryName: 'Saint-Pierre-et-Miquelon',
    code: 508,
    countryFlag: '🇵🇲',
  },
  {
    country: 'PN',
    countryName: 'Pitcairn Islands',
    code: 64,
    countryFlag: '🇵🇳',
  },
  {
    country: 'PR',
    countryName: 'Puerto Rico',
    code: 1,
    countryFlag: '🇵🇷',
  },
  {
    country: 'PS',
    countryName: 'فلسطين',
    code: 970,
    countryFlag: '🇵🇸',
  },
  {
    country: 'PT',
    countryName: 'Portugal',
    code: 351,
    countryFlag: '🇵🇹',
  },
  {
    country: 'PW',
    countryName: 'Palau',
    code: 680,
    countryFlag: '🇵🇼',
  },
  {
    country: 'PY',
    countryName: 'Paraguay',
    code: 595,
    countryFlag: '🇵🇾',
  },
  {
    country: 'QA',
    countryName: 'قطر',
    code: 974,
    countryFlag: '🇶🇦',
  },
  {
    country: 'RE',
    countryName: 'La Réunion',
    code: 262,
    countryFlag: '🇷🇪',
  },
  {
    country: 'RO',
    countryName: 'România',
    code: 40,
    countryFlag: '🇷🇴',
  },
  {
    country: 'RS',
    countryName: 'Србија',
    code: 381,
    countryFlag: '🇷🇸',
  },
  {
    country: 'RU',
    countryName: 'Россия',
    code: 7,
    countryFlag: '🇷🇺',
  },
  {
    country: 'RW',
    countryName: 'Rwanda',
    code: 250,
    countryFlag: '🇷🇼',
  },
  {
    country: 'SA',
    countryName: 'العربية السعودية',
    code: 966,
    countryFlag: '🇸🇦',
  },
  {
    country: 'SB',
    countryName: 'Solomon Islands',
    code: 677,
    countryFlag: '🇸🇧',
  },
  {
    country: 'SC',
    countryName: 'Seychelles',
    code: 248,
    countryFlag: '🇸🇨',
  },
  {
    country: 'SD',
    countryName: 'السودان',
    code: 249,
    countryFlag: '🇸🇩',
  },
  {
    country: 'SE',
    countryName: 'Sverige',
    code: 46,
    countryFlag: '🇸🇪',
  },
  {
    country: 'SG',
    countryName: 'Singapore',
    code: 65,
    countryFlag: '🇸🇬',
  },
  {
    country: 'SH',
    countryName: 'Saint Helena',
    code: 290,
    countryFlag: '🇸🇭',
  },
  {
    country: 'SI',
    countryName: 'Slovenija',
    code: 386,
    countryFlag: '🇸🇮',
  },
  {
    country: 'SJ',
    countryName: 'Svalbard og Jan Mayen',
    code: 4779,
    countryFlag: '🇸🇯',
  },
  {
    country: 'SK',
    countryName: 'Slovensko',
    code: 421,
    countryFlag: '🇸🇰',
  },
  {
    country: 'SL',
    countryName: 'Sierra Leone',
    code: 232,
    countryFlag: '🇸🇱',
  },
  {
    country: 'SM',
    countryName: 'San Marino',
    code: 378,
    countryFlag: '🇸🇲',
  },
  {
    country: 'SN',
    countryName: 'Sénégal',
    code: 221,
    countryFlag: '🇸🇳',
  },
  {
    country: 'SO',
    countryName: 'Soomaaliya',
    code: 252,
    countryFlag: '🇸🇴',
  },
  {
    country: 'SR',
    countryName: 'Suriname',
    code: 597,
    countryFlag: '🇸🇷',
  },
  {
    country: 'SS',
    countryName: 'South Sudan',
    code: 211,
    countryFlag: '🇸🇸',
  },
  {
    country: 'ST',
    countryName: 'São Tomé e Príncipe',
    code: 239,
    countryFlag: '🇸🇹',
  },
  {
    country: 'SV',
    countryName: 'El Salvador',
    code: 503,
    countryFlag: '🇸🇻',
  },
  {
    country: 'SX',
    countryName: 'Sint Maarten',
    code: 721,
    countryFlag: '🇸🇽',
  },
  {
    country: 'SY',
    countryName: 'سوريا',
    code: 963,
    countryFlag: '🇸🇾',
  },
  {
    country: 'SZ',
    countryName: 'Swaziland',
    code: 268,
    countryFlag: '🇸🇿',
  },
  {
    country: 'TC',
    countryName: 'Turks and Caicos Islands',
    code: 1,
    countryFlag: '🇹🇨',
  },
  {
    country: 'TD',
    countryName: 'Tchad',
    code: 235,
    countryFlag: '🇹🇩',
  },
  {
    country: 'TF',
    countryName: 'Territoire des Terres australes et antarctiques fr',
    code: 262,
    countryFlag: '🇹🇫',
  },
  {
    country: 'TG',
    countryName: 'Togo',
    code: 228,
    countryFlag: '🇹🇬',
  },
  {
    country: 'TH',
    countryName: 'ประเทศไทย',
    code: 66,
    countryFlag: '🇹🇭',
  },
  {
    country: 'TJ',
    countryName: 'Тоҷикистон',
    code: 992,
    countryFlag: '🇹🇯',
  },
  {
    country: 'TK',
    countryName: 'Tokelau',
    code: 690,
    countryFlag: '🇹🇰',
  },
  {
    country: 'TL',
    countryName: 'Timor-Leste',
    code: 670,
    countryFlag: '🇹🇱',
  },
  {
    country: 'TM',
    countryName: 'Türkmenistan',
    code: 993,
    countryFlag: '🇹🇲',
  },
  {
    country: 'TN',
    countryName: 'تونس',
    code: 216,
    countryFlag: '🇹🇳',
  },
  {
    country: 'TO',
    countryName: 'Tonga',
    code: 676,
    countryFlag: '🇹🇴',
  },
  {
    country: 'TR',
    countryName: 'Türkiye',
    code: 90,
    countryFlag: '🇹🇷',
  },
  {
    country: 'TT',
    countryName: 'Trinidad and Tobago',
    code: 1,
    countryFlag: '🇹🇹',
  },
  {
    country: 'TV',
    countryName: 'Tuvalu',
    code: 688,
    countryFlag: '🇹🇻',
  },
  {
    country: 'TW',
    countryName: '臺灣',
    code: 886,
    countryFlag: '🇹🇼',
  },
  {
    country: 'TZ',
    countryName: 'Tanzania',
    code: 255,
    countryFlag: '🇹🇿',
  },
  {
    country: 'UA',
    countryName: 'Україна',
    code: 380,
    countryFlag: '🇺🇦',
  },
  {
    country: 'UG',
    countryName: 'Uganda',
    code: 256,
    countryFlag: '🇺🇬',
  },
  {
    country: 'US',
    countryName: 'United States',
    code: 1,
    countryFlag: '🇺🇸',
  },
  {
    country: 'UY',
    countryName: 'Uruguay',
    code: 598,
    countryFlag: '🇺🇾',
  },
  {
    country: 'UZ',
    countryName: 'O‘zbekiston',
    code: 998,
    countryFlag: '🇺🇿',
  },
  {
    country: 'VA',
    countryName: 'Vaticano',
    code: 379,
    countryFlag: '🇻🇦',
  },
  {
    country: 'VC',
    countryName: 'Saint Vincent and the Grenadines',
    code: 1,
    countryFlag: '🇻🇨',
  },
  {
    country: 'VE',
    countryName: 'Venezuela',
    code: 58,
    countryFlag: '🇻🇪',
  },
  {
    country: 'VG',
    countryName: 'British Virgin Islands',
    code: 1,
    countryFlag: '🇻🇬',
  },
  {
    country: 'VN',
    countryName: 'Việt Nam',
    code: 84,
    countryFlag: '🇻🇳',
  },
  {
    country: 'VU',
    countryName: 'Vanuatu',
    code: 678,
    countryFlag: '🇻🇺',
  },
  {
    country: 'WF',
    countryName: 'Wallis et Futuna',
    code: 681,
    countryFlag: '🇼🇫',
  },
  {
    country: 'WS',
    countryName: 'Samoa',
    code: 685,
    countryFlag: '🇼🇸',
  },
  {
    country: 'XK',
    countryName: 'Republika e Kosovës',
    // have multiple code
    // code:377,381,383,386,
    code: 383,
    countryFlag: '🇽🇰',
  },
  {
    country: 'YE',
    countryName: 'اليَمَن',
    code: 967,
    countryFlag: '🇾🇪',
  },
  {
    country: 'YT',
    countryName: 'Mayotte',
    code: 262,
    countryFlag: '🇾🇹',
  },
  {
    country: 'ZA',
    countryName: 'South Africa',
    code: 27,
    countryFlag: '🇿🇦',
  },
  {
    country: 'ZM',
    countryName: 'Zambia',
    code: 260,
    countryFlag: '🇿🇲',
  },
  {
    country: 'ZW',
    countryName: 'Zimbabwe',
    code: 263,
    countryFlag: '🇿🇼',
  },
]
