<template>
  <v-row justify="center">
    <v-dialog
      v-model="visible"
      persistent
      max-width="600"
    >
      <v-card class="d-flex flex-column align-center justify-space-between px-4">
        <v-row justify="space-between" class="my-8 px-2" style="width: 100%;">
          <v-icon
            large
            color="error"
          >
            highlight_off
          </v-icon>
          <v-btn
            icon
            color="primary"
            @click="$store.commit('project/toggleDataDialog', false)"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-row>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row class="mb-8">
                <span v-html="$t('project.requirementStepsForPublish.title')" />
              </v-row>
              <v-row v-for="(requirement, i) in requirements" :key="i" align="start" class="my-2">
                <v-col cols="1">
                  <v-icon color="primary" small>
                    {{ requirement.icon }}
                  </v-icon>
                </v-col>
                <v-col cols="11">
                  <span v-html="$t(`project.requirementStepsForPublish.${requirement.data}`)" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="justify-space-around align-center my-4"
        >
          <v-btn
            color="primary"
            dark
            min-width="12rem"
            elevation="5"
            @click="() => {
              $store.commit('project/toggleDataDialog', false)
              $router.push(firstRequirement.route)
            }"
          >
            {{ $t('common.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PublishProjectMissingData',
  computed: {
    ...mapGetters({ visible: 'project/dataDialog' }),
    ...mapGetters({ requirements: 'project/requirementStepsForPublish' }),
    firstRequirement: ({ requirements }) => requirements[0],
  },
}
</script>
