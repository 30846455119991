<template>
  <div class="file-field">
    <label v-if="currentNameFile" class="file-field__name-file d-block overflow-hidden text-truncate mt-2 ml-3" v-text="currentNameFile" />
    <v-file-input
      :value="currentFile" :prepend-icon="null" outlined dense persistent-placeholder
      :rules="rules" :accept="accept" :label="label" :error-messages="errorMessages"
      :class="{ 'file-field--has-name-file': currentNameFile }" @change="$emit('input', $event)"
    >
      <template #append>
        <v-btn
          v-if="currentFileType === 'string'"
          class="download-btn" color="primary"
          fab small absolute bottom right
          :href="value" target="_blank" download
        >
          <v-icon :color="currentFileType === 'File' ? 'primary' : null" v-text="currentFileType === 'File' ? 'publish' : 'get_app'" />
        </v-btn>
      </template>
    </v-file-input>
  </div>
</template>
<script>

export default {
  name: 'FileField',
  props: {
    accept: { type: String, default: '*' },
    value: { type: [File, String], default: null },
    label: { type: String, default: null },
    maxSize: { type: Number, default: 3000000 },
    errorMessages: { type: String, default: '' },
  },
  computed: {
    KBInMB: ({ maxSize }) => maxSize / 1000000,
    rules() {
      return [
        ...(this.maxSize && this.currentFileType === 'file'
          ? [value => value?.size > this.maxSize ? this.$t('validation.fileSize', { size: `${this.KBInMB}MB` }) : true]
          : []),
      ]
    },
    currentFileType: ({ value }) => {
      if (typeof value === 'string') return 'string'
      if (typeof value === 'object' && value instanceof File) return 'file'
    },
    currentNameFile: ({ value }) => (typeof value === 'string') ? value.split('%2F').pop().split('?alt')[0] : null,
    currentFile: ({ value, currentFileType }) => currentFileType === 'file' ? value : null,
  },
}
</script>
<style lang="scss" scoped>
.file-field {
  position: relative;
}
.file-field .file-field__name-file {
  position: absolute;
  width: 68%;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 78%;
  }
}

::v-deep .file-field {
  &--has-name-file .v-input__icon--clear {
    display: none;
  }
}
</style>
