<template>
  <v-row>
    <v-col cols="6" sm="6">
      <v-text-field
        v-model="model.name"
        outlined
        :label="$t('common.name')"
        dense
        :error-messages="nameErrorMessages"
        @input="$emit('name-input')"
      />
    </v-col>
    <v-col cols="6" sm="6">
      <v-text-field
        v-model="model.url"
        outlined
        :label="$t('common.url')"
        dense
        :error-messages="urlErrorMessages"
        @input="$emit('url-input')"
      />
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'LinkField',
  props: {
    value: { type: Object, default: null },
    nameErrorMessages: { type: Array, default: null },
    urlErrorMessages: { type: Array, default: null },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      model: {
        name: null,
        url: null,
      },
    }
  },
  watch: {
    value() {
      this.model.name = this.value.name ?? null
      this.model.url = this.value.url ?? null
    },
    model: {
      deep: true,
      handler({ name, url }) {
        this.$emit('input', {
          name,
          url,
        })
      },
    },
  },
  created() {
    this.model.name = this.value.name ?? null
    this.model.url = this.value.url ?? null
  },
}
</script>
