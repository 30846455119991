<template>
  <v-container class="d-flex align-center">
    <v-app-bar class="toolbar" flat app color="white">
      <img contain :src="require('@/assets/logo.png')" class="logo">
      <v-spacer />
      <v-btn color="primary" outlined class="d-flex ma-4" href="mailto:support@owqlohelp.zendesk.com">
        <v-icon left>
          support_agent
        </v-icon>
        {{ $t('user.contactSupport') }}
      </v-btn>
      <v-menu bottom left offset-y nudge-bottom="7">
        <template #activator="{ on }">
          <v-btn icon fab v-on="on">
            <v-icon>translate</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="locale in locales" :key="locale.code" @click="changeLocale(locale.code)">
            <v-list-item-title>{{ locale.language }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main class="container">
      <slot />
    </v-main>
  </v-container>
</template>

<script>
import localeUtils from '@/utils/mixins/localeUtils'

export default {
  name: 'NoSidebarLayout',
  mixins: [localeUtils],
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 800px;
}
.v-app-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.logo {
  max-height: 24px;
}
</style>
