<template>
  <v-list-item>
    <div class="d-flex flex-row justify-space-between">
      <v-list-item-content>
        <h4>{{ $t(title) }}</h4>
      </v-list-item-content>
      <v-list-item-action>
        <global-tooltip :text="$t(tooltip)" />
      </v-list-item-action>
    </div>
  </v-list-item>
</template>

<script>
export default {
  name: 'SidebarTitle',
  components: {
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
}
</script>
