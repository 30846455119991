<template>
  <div>
    <v-text-field
      :value="value"
      :label="$t('project.editProject.videoLabel')"
      :error-messages="errorMessages"
      type="url"
      outlined
      dense
      @input="$emit('input', $event)"
    >
      <template #append>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon color="primary" v-on="on" @click="uploadDialog = true">
              publish
            </v-icon>
          </template>
          {{ $t('formFields.uploadVideo') }}
        </v-tooltip>
      </template>
    </v-text-field>
    <v-dialog v-model="uploadDialog" max-width="800px" persistent>
      <v-card class="pa-8">
        <v-btn icon color="black" class="close-dialog" @click="uploadDialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-title class="pa-0 mb-4" v-text="$t('formFields.uploadVideo')" />
        <div class="video-input__wrapper">
          <div v-ripple class="video-input" @click="$refs.realFileInput.click()">
            <v-icon x-large>
              backup
            </v-icon>
            <div class="secondary--text" v-text="$t('formFields.chooseVideo')" />
          </div>
          <input
            ref="realFileInput" type="file"
            accept="video/mp4"
            style="display: none;"
            @change="uploadVideo($event.target.files[0])"
          >
          <v-overlay absolute :value="isUploading" :z-index="303" :opacity="1" color="#f5f5f5">
            <v-progress-circular :value="Number(uploadProgress)" size="120" width="15" color="primary">
              {{ `${uploadProgress} %` }}
            </v-progress-circular>
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { vimeoApi } from '@/services/vimeo'
import * as tusClient from 'tus-js-client'

// PROBLEM WHEEN UPLOADING A SECOND TIME
export default {
  name: 'VideoField',
  props: {
    value: { type: String, default: null },
    errorMessages: { type: String, default: '' },
  },
  data() {
    return {
      isUploading: false,
      uploadProgress: '0',
      uploadDialog: false,
    }
  },
  methods: {
    async finishUpload(newVideoData) {
      this.isUploading = false
      this.uploadProgress = '0'
      this.$emit('input', newVideoData.link)
      this.uploadDialog = false
      this.$store.dispatch('alert/openAlertBox', ['alertSuccess', 'Success'])
    },
    async uploadVideo(file) {
      if (!file) return
      this.isUploading = true
      const options = { upload: { approach: 'tus', size: file.size }, name: file.name }
      const newVideoData = (await vimeoApi.post(null, options)).data
      const uploadLink = newVideoData.upload.upload_link
      const upload = new tusClient.Upload(file, {
        uploadUrl: uploadLink,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        onError(err) { this.$store.dispatch('alert/openAlertBox', ['alertError', 'Upload failed']); console.error(err) },
        onProgress: (bytesUploaded, bytesTotal) => { this.uploadProgress = ((bytesUploaded / bytesTotal) * 100).toFixed() },
        onSuccess: () => this.finishUpload(newVideoData),
      })
      upload.start()
    },
  },
}
</script>

<style lang="scss">
.video-input__wrapper {
  position: relative;
}

.video-input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  min-height: 200px;
  background-color: #f5f5f5;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;
}
</style>
