<template>
  <v-menu ref="menu" :close-on-content-click="false" transition="scroll-x-transition" min-width="290px">
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="formattedBirthdate"
        :label="$t('user.fields.birthdate')"
        :error-messages="errorMessages"
        type="text" name="bday"
        prepend-inner-icon="event"
        readonly
        outlined
        dense
        v-bind="attrs"
        v-on="on"
        @click="startWithYear"
        @blur="$emit('blur')"
      />
    </template>
    <!-- TODO: Temporally por jugador-->
    <v-date-picker
      ref="picker"
      :value="value"
      :max="`${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}`"
      :min="`${new Date().getFullYear() - 100}-${new Date().getMonth()}`"
      @input="$emit('input', $event)"
      @change="$refs.menu.save()"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'BirthdayField',
  props: {
    value: { type: String, default: null },
    errorMessages: { type: String, default: '' },
  },
  computed: {
    formattedBirthdate: ({ value }) => value && new Date(value).toLocaleDateString(),
  },
  methods: {
    startWithYear() { setTimeout(() => { this.$refs.picker.activePicker = 'YEAR' }) },
  },
}
</script>
