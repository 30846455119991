<template>
  <v-input
    hide-details="auto"
    :error-messages="customError"
  >
    <template #default>
      <input
        ref="autocomplete"
        type="text"
        :placeholder="customLabel || $t('project.academy.calendar.locationInput')"
        :class="`search-location${(!location && isDirty) || customError ? ' search-location-validation-error' : ''}`"
        :value="location"
        @blur="$emit('blur')"
      >
      <v-icon>location_on</v-icon>
    </template>
  </v-input>
</template>

<script>
export default {
  name: 'Geolocation',
  props: {
    location: {
      type: String,
      default: '',
    },
    isDirty: {
      type: Boolean,
      default: false,
    },
    customLabel: {
      type: String,
      default: null,
    },
    customError: {
      type: String,
      default: null,
    },
  },
  async mounted() {
    /* eslint-disable no-undef */

    this.autocomplete = new google.maps.places.SearchBox(
      this.$refs.autocomplete,
      { types: ['geocode'] },
    )

    this.autocomplete.addListener('places_changed', async () => {
      const { name, geometry } = await this.autocomplete.getPlaces()[0]
      this.$emit('update', {
        name,
        longitude: geometry?.location?.lng(),
        latitude: geometry?.location?.lat(),
      })
    })

    this.$refs.autocomplete.addEventListener('keyup', e => {
      if (!e.target.value) this.$emit('update', null)
    })
  },
}
</script>

<style lang="scss" scoped>
.search-location {
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  line-height: 20px;
  border-color: rgba(0, 0, 0, 0.38);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-collapse: collapse;
  cursor: pointer;
  transition: 0.3s;
}

.search-location:focus {
  border: none;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 0 0 2px #2acccc;
  transition: 0.3s;
}

.search-location-validation-error {
  border: none;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 0 0 2px #e02020 !important;
  transition: 0.3s;
}

.v-icon {
  position: absolute;
  margin-top: 2px;
  right: 12px;
}
</style>
