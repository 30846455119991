<template>
  <v-row>
    <v-col v-if="regions.length" cols="12" sm="6">
      <v-select
        v-model="regionId"
        :items="regions" item-text="name" item-value="id" :label="$t('common.selectRegion')"
        :error-messages="regionId ? '' : errorMessages" append-icon="mdi-chevron-down" outlined dense
        @change="$emit('input', null)"
      />
    </v-col>
    <v-col cols="12" :sm="regions.length ? '6' : '12'">
      <v-select
        :value="value ? cohorts.find(c => c.id === value).categoryId : null"
        :items="categoryItems" item-text="name" item-value="id" :disabled="!!regions.length && !regionId"
        :error-messages="errorMessages" :label="$t('common.selectCategory')" append-icon="mdi-chevron-down" outlined dense
        @change="$emit('input', cohorts.find(c => c.regionId === regionId && c.categoryId === $event).id)"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CohortField',
  props: {
    value: { type: String, default: null },
    errorMessages: { type: String, default: null },
    disabledCohortIds: { type: Array, default: () => [] },
  },
  data() {
    return {
      regionId: null,
    }
  },
  computed: {
    ...mapGetters({ cohorts: 'competition/cohorts' }),
    ...mapGetters({ regions: 'competition/regions' }),
    ...mapGetters({ categories: 'competition/categories' }),
    categoryItems: ({ categories, regions, regionId, disabledCohortIds, cohorts, value }) => categories
      .filter(category => regionId ? regions.find(region => region.id === regionId).categoryIds.includes(category.id) : true)
      .filter(category => !disabledCohortIds.some(cohortId => {
        const cohort = cohorts.find(c => c.id === cohortId)
        if (!cohort || cohort.id === value) return false
        return cohort.regionId === regionId && cohort.categoryId === category.id
      })),
  },
  created() {
    if (this.value) this.regionId = this.cohorts.find(c => c.id === this.value).regionId
  },
}
</script>
