<template>
  <v-list-item
    :key="item.name"
    :to="item.route"
    link
    color="primary"
    :disabled="item.disabled"
  >
    <v-list-item-icon>
      <v-icon class="material-icons material-icons-outlined" color="primary">
        {{ item.icon }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title :style="isCurrentView">
        {{ $t(item.name) }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarRoutingItem',
  props: {
    item: {
      type: Object,
      default: null,
    },
    route: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({ data: 'project/data' }),
    isCurrentView({ item, route }) {
      return item.route?.name === route
        ? {
          color: 'rgba(0, 0, 0, 0.87) !important',
          fontWeight: '700',
        } : {
          fontWeight: '400',
        }
    },
  },
}
</script>
