<template>
  <v-container
    fluid
    class="d-flex flex-column justify-space-between px-2 v-container__languages"
  >
    <v-row no-gutters style="flex: none !important;">
      <h3 class="ml-1">
        {{ `${$t('common.languages')} (${localItems.length})` }}
      </h3>
    </v-row>
    <v-row no-gutters class="d-flex flex-column justify-start py-2">
      <v-list
        v-for="item in localItems"
        :key="item.id"
        dense
        class="v-list__languages"
        :color="item.selected ? 'rgba(42, 204, 204, 0.3)' : undefined"
      >
        <terms-list-item
          :item="item"
          @onDelete="localItems = localItems.filter(e => e.id !== item.id)"
          @onSelectDefault="localItems = localItems.map(e => ({ ...e, isDefault: e.id === item.id }))"
          @onUpdate="showLanguageListFormDialog = true; itemToUpdate = item"
        />
      </v-list>
    </v-row>
    <terms-list-form-dialog
      v-if="showLanguageListFormDialog"
      :item="itemToUpdate"
      :languages-used="localItems.map(e => e.id)"
      @add="(item) => { localItems.push({...item}); } "
      @update="(item) => { localItems = localItems.map(e => e.id==item.id ? {...item}: e) }"
      @close-dialog="showLanguageListFormDialog = false"
    />
    <v-row
      no-gutters
      style="flex: initial !important;"
    >
      <v-btn icon small style="width: auto;" color="primary" @click="showLanguageListFormDialog = true; itemToUpdate = null">
        <v-icon
          dark
          class="mx-1"
        >
          add_box
        </v-icon>
        <span>
          {{ $t('organization.settings.add') }}
        </span>
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import localeUtils from '@/utils/mixins/localeUtils'

export default {
  name: 'Terms',
  components: {
    TermsListItem: () => import('@/components/terms/TermsListItem'),
    TermsListFormDialog: () => import('@/components/terms/TermsListFormDialog'),
  },
  mixins: [localeUtils],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showLanguageListFormDialog: false,
      localItems: [],
      itemToUpdate: null,
    }
  },
  watch: {
    localItems(newValue, oldValue) {
      this.$emit('on-changed', newValue)
    },
  },
  created() {
    this.localItems = [...this.items]
  },
}
</script>

<style scoped>

.v-container__languages {
  height: 100%;
  background: #f5f5f5;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-shadow: 2px 2px 2px 1px #f5f5f5;
}

.v-list__languages {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  max-width: 100%;
  height: 56px;
  margin: 2px;
  background: white;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-shadow: 2px 2px 2px 1px #f5f5f5;
}

.v-list__languages-list {
  height: 400px;
  overflow-y: auto;
}
</style>
