<template>
  <v-tooltip top color="white">
    <template #activator="{ on, attrs }">
      <v-icon v-bind="attrs" color="primary" dark class="primary--text" v-on="on" v-text="icon ? icon : 'info_outline'" />
    </template>
    <div class="tooltip-text pa-2" v-text="text" />
  </v-tooltip>
</template>

<script>
export default {
  name: 'GlobalTooltip',
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
}
</script>
