<template>
  <v-row>
    <v-col :cols="fullWidth ? '6' : '5'">
      <v-menu
        v-model="isDatePickerOpen"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <!-- This component only emits a value once date and time are filled -->
          <!-- We only emit blur in the time field so required error doesn't appear until user enters date  -->
          <v-text-field
            :value="formattedDate"
            :label="dateLabel"
            :error-messages="isoDate ? '' : errorMessages"
            :append-icon="isoDate != null ? null : 'event' "
            outlined
            clearable
            readonly
            dense
            hide-details="auto"
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
            @click:clear=" isoDate = null; emitDatetime()"
          />
        </template>
        <v-date-picker
          v-model="isoDate"
          :min="minIsoDate"
          :max="maxIsoDate"
          @input="emitDatetime"
        />
      </v-menu>
    </v-col>
    <v-col v-if="!fullWidth" cols="1" />
    <v-col :cols="fullWidth ? '6' : '5'">
      <v-menu
        ref="timeMenu"
        v-model="isTimePickerOpen"
        :close-on-content-click="false"
        :close-on-click="isExpectingMinutes ? false : true"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            :value="isoTime"
            :label="timeLabel"
            :error-messages="isoTime ? '' : errorMessages"
            :error="isoDate && !(isoTime)"
            :append-icon="'access_time'"
            outlined
            readonly
            dense
            hide-details="auto"
            :disabled="disabled || !isoDate"
            v-bind="attrs"
            v-on="on"
            @blur="$emit('blur')"
          />
        </template>
        <v-time-picker
          v-if="isTimePickerOpen"
          v-model="isoTime"
          format="24hr"
          :min="isMinDateSelected ? minIsoTime : null"
          :max="isMaxDateSelected ? maxIsoTime : null"
          @click:hour="isExpectingMinutes = true"
          @click:minute="emitDatetime"
        />
      </v-menu>
    </v-col>
    <v-col v-if="!fullWidth" cols="1" />
  </v-row>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'DatetimeField',
  props: {
    value: { type: Date, default: null },
    timezone: { type: String, required: true },
    dateLabel: { type: String, default() { return this.$t('common.date') } },
    timeLabel: { type: String, default() { return this.$t('common.time') } },
    errorMessages: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    minDatetime: { type: Date, default: null },
    maxDatetime: { type: Date, default: null },
    initialIsoDate: { type: String, default: null },
    initialIsoTime: { type: String, default: null },
    fullWidth: { type: Boolean, default: false },
  },
  data() {
    return {
      isDatePickerOpen: false,
      isTimePickerOpen: false,
      isoDate: null,
      isoTime: null,
      isExpectingMinutes: false,
    }
  },
  computed: {
    formattedDate: ({ isoDate }) => isoDate ? DateTime.fromISO(isoDate).toLocaleString() : null,
    minIsoDate: ({ minDatetime, timezone }) => minDatetime ? DateTime.fromJSDate(minDatetime, { zone: timezone }).toISODate() : null,
    minIsoTime: ({ minDatetime, timezone }) => minDatetime ? DateTime.fromJSDate(minDatetime, { zone: timezone }).plus({ minutes: 2 }).toISOTime() : null,
    maxIsoDate: ({ maxDatetime, timezone }) => maxDatetime ? DateTime.fromJSDate(maxDatetime, { zone: timezone }).toISODate() : null,
    maxIsoTime: ({ maxDatetime, timezone }) => maxDatetime ? DateTime.fromJSDate(maxDatetime, { zone: timezone }).minus({ minutes: 2 }).toISOTime() : null,
    isMinDateSelected: ({ minIsoDate, isoDate }) => minIsoDate === isoDate,
    isMaxDateSelected: ({ maxIsoDate, isoDate }) => maxIsoDate === isoDate,
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.isoDate = value ? DateTime.fromJSDate(value, { zone: this.timezone }).toISODate() : null
        this.isoTime = value ? DateTime.fromJSDate(value, { zone: this.timezone }).toISOTime().slice(0, 5) : null
      },
    },
  },
  created() {
    if (this.initialIsoDate) this.isoDate = this.initialIsoDate
    if (this.initialIsoTime) this.isoTime = this.initialIsoTime
  },
  methods: {
    emitDatetime() {
      this.isDatePickerOpen = false
      this.isTimePickerOpen = false
      this.isExpectingMinutes = false
      let datetime = null
      if (this.isoDate) {
        this.isoTime = this.isoTime ?? '00:00'
        datetime = DateTime.fromISO(`${this.isoDate}T${this.isoTime}:00`, { zone: this.timezone }).toJSDate()
      }
      this.$emit('input', datetime)
    },
  },
}
</script>
