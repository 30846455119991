<template>
  <v-menu
    ref="menu"
    v-model="openMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        :error-messages="errorMessages"
        append-icon="access_time"
        outlined
        readonly
        dense
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        @blur="$emit('blur')"
        @input="$emit('input')"
      />
    </template>
    <v-time-picker
      v-if="openMenu"
      v-model="time"
      format="24hr"
      full-width
      :min="min" :max="max"
      @click:minute="$refs.menu.save(time)"
    />
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon'
import { Timestamp } from '@/services/firebase'

export default {
  name: 'TimeField',
  props: {
    value: { type: [Object, String], default: null },
    label: { type: String, default: '' },
    timezone: { type: String, default: '' },
    errorMessages: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    min: { type: String, default: null },
    max: { type: String, default: null },
  },
  data() {
    return {
      openMenu: false,
      time: null,
    }
  },
  watch: {
    time(val) {
      this.$emit('input', val)
    },
    value: {
      immediate: true,
      handler(val) {
        this.time = val instanceof Timestamp ? this.formatTime(val) : val
      },
    },
  },
  methods: {
    formatTime(date) {
      const time = DateTime.fromSeconds(date.seconds, {
        zone: this.timezone,
      }).toLocaleString(DateTime.TIME_24_SIMPLE)

      return time.split(':')[0].length === 1 ? `0${time}` : time
    },
  },
}
</script>
