import Vue from 'vue'

export default {
  functional: true,
  props: { type: { type: String } },
  render(createElement, { props: { type } }) {
    const element = `PublishProject${type}`
    Vue.component(element, require(`@/components/${element}.vue`).default)
    return createElement(element)
  },
}
