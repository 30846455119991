<template>
  <div>
    <div class="video-input__wrapper">
      <div v-ripple class="video-input" :class="{ 'video-input--error': errorMessages.length }" @click="$refs.realFileInput.click()">
        <v-icon x-large>
          backup
        </v-icon>
        <div class="secondary--text" v-text="$t('formFields.chooseVideo')" />
        <div v-if="thumbnail" class="video-input__overlay">
          <img class="video-input__overlay-image" :src="thumbnail" @load="showLoader = false">
        </div>
      </div>
      <input
        ref="realFileInput" type="file"
        accept="video/mp4"
        style="display: none;"
        @change="uploadVideo($event.target.files[0])"
      >
      <v-btn
        v-if="currentVideo"
        class="download-btn"
        color="primary"
        fab small absolute bottom right
        :href="currentVideo" download
      >
        <v-icon>get_app</v-icon>
      </v-btn>
      <v-overlay absolute :value="showLoader" :z-index="303" :opacity="1" color="#f5f5f5">
        <v-progress-circular v-if="isUploading" :value="Number(uploadProgress)" size="120" width="15" color="primary">
          {{ `${uploadProgress} %` }}
        </v-progress-circular>
        <v-progress-circular v-else indeterminate size="50" width="4" color="primary" />
      </v-overlay>
    </div>
    <v-input :error-messages="errorMessages" error />
  </div>
</template>

<script>
import { vimeoApi, getVideoId } from '@/services/vimeo'
import * as tusClient from 'tus-js-client'

export default {
  name: 'VideoFieldWithThumbnail',
  props: {
    value: { type: String, default: null },
    errorMessages: { type: String, default: '' },
  },
  data() {
    return {
      currentVideo: null,
      thumbnail: null,
      defaultThumbnail: '/video_placeholder.jpeg',
      showLoader: false,
      isUploading: false,
      uploadProgress: '0',
    }
  },
  async created() {
    if (!this.value) return
    this.showLoader = true
    const videoId = await getVideoId(this.value)
    if (videoId) {
      const videoData = (await vimeoApi.get(`${videoId}`)).data
      this.thumbnail = videoData.pictures.sizes[4].link_with_play_button
      this.currentVideo = videoData.download[0].link
    } else {
      this.thumbnail = this.defaultThumbnail // Happens when the video is new and still being processed by Vimeo
    }
  },
  methods: {
    prepareToUpload() {
      this.showLoader = true
      this.isUploading = true
      this.currentVideo = null
    },
    async finishUpload(newVideoData) {
      this.isUploading = false
      this.uploadProgress = '0'
      this.thumbnail = this.defaultThumbnail
      this.$emit('input', newVideoData.link)
    },
    async uploadVideo(file) {
      if (!file) return
      this.prepareToUpload()
      const options = { upload: { approach: 'tus', size: file.size }, name: file.name }
      const newVideoData = (await vimeoApi.post(null, options)).data
      const uploadLink = newVideoData.upload.upload_link
      const upload = new tusClient.Upload(file, {
        uploadUrl: uploadLink,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        onError(err) { this.$store.dispatch('alert/openAlertBox', ['alertError', 'Upload failed']); console.error(err) },
        onProgress: (bytesUploaded, bytesTotal) => { this.uploadProgress = ((bytesUploaded / bytesTotal) * 100).toFixed() },
        onSuccess: () => this.finishUpload(newVideoData),
      })
      upload.start()
    },
  },
}
</script>

<style lang="scss">
.video-input__wrapper {
  position: relative;
}

.video-input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  min-height: 200px;
  background-color: #f5f5f5;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;

  &--error {
    outline: 2px solid #e02020;
  }
}

.video-input__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.video-input__overlay-image {
  object-fit: cover;
  background-color: #ccc;
}
</style>
