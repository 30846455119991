<template>
  <div>
    <div v-if="events.length" class="px-4 py-6">
      <section v-if="sections.some(section => section.events.length)">
        <div v-for="section in sections" :key="section.id" class="mb-4">
          <h3 v-if="section.events.length" class="pl-4" v-text="$t(section.name)" />
          <v-list two-line class="pt-1">
            <template v-for="(ev, i) in section.events">
              <v-list-item :key="i">
                <Avatar :url="ev.user.avatar" :name="ev.user.name" class="mr-3" />
                <v-list-item-content>
                  <v-list-item-title class="mb-1">
                    <!-- User -->
                    <span class="font-weight-bold" v-text="`${ev.user.name} `" />
                    <!-- Action -->
                    <span v-text="`${$t('eventLog.actionTextPrefix')} `" />
                    <span :class="`${getActionColor(ev.action)}--text text--darken-1`" v-text="`${$t(`eventLog.actionTexts.${ev.action}`)} `" />
                    <template v-if="['addUser', 'removeUser'].includes(ev.action)">
                      <span v-text="`${$t(`eventLog.actionTexts.${ev.action}2`)} `" />
                      <span v-text="`${ev.targetUser.name} `" />
                      <span v-text="`${$t(`eventLog.actionTexts.${ev.action}3`)} `" />
                      <span class="text-lowercase" v-text="`${$t(`eventLog.userRoles.${ev.targetUser.role}`)} `" />
                      <span v-text="`${$t(`eventLog.actionTexts.${ev.action}4`)} `" />
                    </template>
                    <!-- Entity -->
                    <span v-if="routeEntityType === ev.entity.type" v-text="`${$t(`eventLog.entityArticle`)} `" />
                    <span class="text-lowercase" v-text="`${$t(`eventLog.entityTypes.${ev.entity.type}`)} `" />
                    <template v-if="routeEntityType !== ev.entity.type">
                      <span v-if="ev.action === 'delete'" class="text-lowercase" v-text="`${ev.entity.name} `" />
                      <template v-else>
                        <span><router-link v-if="getEntityRoute(ev)" :to="getEntityRoute(ev)" class="text-lowercase" v-text="`${ev.entity.name}`" /></span>
                        <span v-text="` `" />
                      </template>
                    </template>
                    <!-- Extra -->
                    <template v-if="ev.action === 'rename'">
                      <span v-text="`${$t(`eventLog.actionTexts.${ev.action}2`)} `" />
                      <span v-text="`${ev.newName} `" />
                    </template>
                  </v-list-item-title>
                  <v-list-item-subtitle v-text="`${ev.date.toLocaleDateString()} ·  ${ev.date.toLocaleTimeString().slice(0, 5)}`" />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </div>
      </section>
      <empty-list v-else :content="$t('common.recent')" />
    </div>
    <empty-list v-else :content="$t('common.events')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import projectRouteNames from '@/enums/projectRouteNames'

export default {
  name: 'EventLog',
  components: {
    Avatar: () => import('@/components/Avatar'),
    EmptyList: () => import('@/components/EmptyList'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, default: null },
  },
  computed: {
    ...mapGetters(projectRouteNames.ORGANIZATION, ['isUserAdmin', 'rawEvents']),
    ...mapGetters(projectRouteNames.USER, ['collabProjectIds']),
    filteredByTypeEvents: ({ routeEntityType, projectId, rawEvents }) => ((routeEntityType === projectRouteNames.ORGANIZATION)
      ? rawEvents.filter(ev => [projectRouteNames.ORGANIZATION, projectRouteNames.PROJECT].includes(ev.entity.type))
      : rawEvents.filter(ev => [
        projectRouteNames.PROJECT,
        projectRouteNames.ACTIVITY,
        projectRouteNames.POST,
      ].includes(ev.entity.type) && ev.context.projectId === projectId)),
    filteredByUserEvents: ({ routeEntityType, filteredByTypeEvents, isUserAdmin, collabProjectIds }) => (
      (routeEntityType === projectRouteNames.ORGANIZATION && !isUserAdmin)
        ? filteredByTypeEvents.filter(ev => (!ev.context.projectId || collabProjectIds.includes(ev.context.projectId)))
        : filteredByTypeEvents),
    events: ({ filteredByUserEvents }) => filteredByUserEvents
      .map(ev => ({ ...ev, date: new Date(ev.createdAt.seconds * 1000) })).reverse(),
    sections: ({ events, isDateFromToday, isDateFromLastWeek, isDateFromLastMonth }) => ([
      {
        id: 'today',
        name: 'eventLog.sections.today',
        events: events.filter(ev => isDateFromToday(ev.date)),
      },
      {
        id: 'lastWeek',
        name: 'eventLog.sections.lastWeek',
        events: events.filter(ev => isDateFromLastWeek(ev.date)),
      },
      {
        id: 'lastMonth',
        name: 'eventLog.sections.lastMonth',
        events: events.filter(ev => isDateFromLastMonth(ev.date)),
      },
    ]),
    routeEntityType({ projectId }) { return projectId ? projectRouteNames.PROJECT : projectRouteNames.ORGANIZATION },
  },
  methods: {
    getEntityRoute: ({ entity, context }) => ({
      name: entity.type,
      params: {
        organizationId: context.organizationId,
        projectId: context.projectId,
        ...(context?.activityId && ({ activityId: context?.activityId })),
        ...(context?.postId && ({ postId: context?.postId })),
      },
    }),
    getActionColor: action => {
      if (['create', 'add'].some(word => action.includes(word))) return 'success'
      if (['delete', 'remove'].some(word => action.includes(word))) return 'error'
      return 'inherit'
    },
    isDateFromToday: date => date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth(),
    isDateFromLastWeek: date => {
      const oneWeek = 7 * 24 * 60 * 60 * 1000
      const timeDifference = new Date().getTime() - date.getTime()
      return timeDifference >= 0 && timeDifference < oneWeek
    },
    isDateFromLastMonth: date => {
      const oneMonth = 30 * 24 * 60 * 60 * 1000
      const timeDifference = new Date().getTime() - date.getTime()
      return timeDifference >= 0 && timeDifference < oneMonth
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  color: inherit !important;
  text-decoration: underline;
}
</style>
