<template>
  <div class="d-flex">
    <v-autocomplete
      class="mr-6" style="width: 120px" :label="$t('common.prefix')"
      :value="phoneCodes.find(e => e.country === value.country)" :items="phoneCodes" :disabled="disabled"
      :item-text="item => `${item.countryFlag} +${item.code} ${item.countryName}`" return-object
      outlined dense append-icon="mdi-chevron-down" :error-messages="errorMessages"
      @input="$emit('input', { country: $event ? $event.country : null, code: $event ? $event.code : null, number: value.number })"
    />
    <v-text-field
      :label="$t('user.fields.phone')" :value="value.number" type="number" min="0" max="999999999"
      outlined dense :error-messages="errorMessages" :disabled="disabled"
      @keypress="validateKeyNumeric($event)"
      @input="$emit('input', { country: value.country, code: value.code, number: $event ? parseInt($event) : null } )"
      @blur="$emit('blur')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import phoneCodes from '@/enums/phoneCodes'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'PhoneField',
  mixins: [formUtils],
  props: {
    value: { type: Object, default: () => ({ country: null, code: null, number: null }) },
    errorMessages: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({ user: 'user/data' }),
    phoneCodes: ({ user }) => phoneCodes.findIndex(e => e.country === user?.country) !== -1
      ? [phoneCodes.find(e => e.country === user?.country), ...phoneCodes.filter(e => e.country !== user?.country)]
      : phoneCodes,
  },
}
</script>
