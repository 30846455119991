<template>
  <div class="links-field">
    <div v-for="(item, index) in links" :key="index">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <a
            class="mr-4"
            color="primary"
            :href="item.url"
            target="_blank"
            rel="noopener noreferrer"
          >{{ item.name }}</a>
          <v-icon v-on="on">
            visibility
          </v-icon>
        </template>
        <span>{{ item.url }}</span>
      </v-tooltip>
      <v-btn icon color="error" @click="handleOnRemove(index)">
        <v-icon>highlight_off</v-icon>
      </v-btn>
    </div>
    <link-field v-model="newLink" :name-error-messages="nameErrors" :url-error-messages="urlErrors" />
    <v-btn
      :disabled="disabledButton"
      outlined
      color="primary"
      @click="handleAdd"
      v-text="$t('common.add')"
    />
  </div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators'

export default {
  name: 'LinksField',
  components: {
    LinkField: () => import('@/components/formFields/LinkField'),
  },
  props: {
    value: { type: Array, default: null },
    errorMessages: { type: String, default: '' },
  },
  validations() {
    return {
      newLink: {
        name: { required },
        url: { required, url },
      },
    }
  },
  data() {
    return {
      links: [],
      newLink: { name: null, url: null },
    }
  },
  computed: {
    disabledButton({ newLink }) {
      return !newLink.name || !newLink.url
    },
    nameErrors() {
      const errors = []
      if (this.errorMessages) errors.push(this.errorMessages)
      if (!this.$v.newLink.name.$dirty) return errors
      if (!this.$v.newLink.name.required) errors.push(this.$t('validation.required'))
      return errors
    },
    urlErrors() {
      const errors = []
      if (this.errorMessages) errors.push(this.errorMessages)
      if (!this.$v.newLink.url.$dirty) return errors
      if (!this.$v.newLink.url.required) errors.push(this.$t('validation.required'))
      if (!this.$v.newLink.url.url) errors.push(this.$t('validation.url'))
      return errors
    },
  },
  watch: {
    links: {
      deep: true,
      handler(links) {
        this.$emit('input', links)
      },
    },
  },
  created() {
    this.links = this.value ?? []
  },
  methods: {
    resetNewLink() {
      this.newLink = { name: null, url: null }
      this.$v.newLink.$reset()
    },
    handleAdd() {
      const urls = this.links.map(link => link.url)
      const urlToSave = this.newLink.url

      this.$v.newLink.$touch()
      if (this.$v.newLink.$invalid) return

      if (urls.includes(urlToSave)) {
        this.$store.dispatch('alert/openAlertBox', ['alertError', `Url ${urlToSave} already exists`])
        return
      }

      this.links.push(this.newLink)
      this.resetNewLink()
    },
    handleOnRemove(index) {
      const linkToRemove = this.links[index]
      this.links = this.links.filter(link => link.url !== linkToRemove.url)
    },
  },
}
</script>
