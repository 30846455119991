<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model.number="model.min"
        type="number"
        outlined
        :label="$t('common.min')"
        dense
        :error-messages="minErrorMessages"
        @blur="$emit('min-blur')"
        @input="$emit('min-input')"
      />
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model.number="model.max"
        type="number"
        outlined
        :label="$t('common.min')"
        dense
        :error-messages="maxErrorMessages"
        @blur="$emit('max-blur')"
        @input="$emit('max-input')"
      />
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'AgeRangeField',
  props: {
    value: { type: Object, default: null },
    minLabel: { type: String, default: null },
    maxLabel: { type: String, default: null },
    minErrorMessages: { type: String, default: '' },
    maxErrorMessages: { type: String, default: '' },
  },
  data() {
    return {
      model: {
        min: null,
        max: null,
      },
    }
  },
  watch: {
    model: {
      deep: true,
      handler({ min, max }) {
        this.$emit('input', { min, max })
      },
    },
  },
  created() {
    this.model.min = this.value ? this.value.min : null
    this.model.max = this.value ? this.value.max : null
  },
}
</script>
